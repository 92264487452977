// Modules opbouwen via volgende structuur
// Meer info: https://learn.jquery.com/code-organization/concepts/

(function($){

	$('.js-gallery').each(function(index , value){
		$(this).magnificPopup({
			delegate: 'a',
			type: 'image',
			tLoading: 'Loading image #%curr%...',
			mainClass: 'mfp-img-mobile',
			gallery: {
				enabled: true,
				navigateByImgClick: true,
				preload: [0,1] // Will preload 0 - before current, and 1 after the current image
			},
			image: {
				tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
				titleSrc: function(item) {
					return item.el.attr('title');
				}
			}
		});
	});

/*
	$('.btn--book').each(function() {
		$(this).magnificPopup({
				disableOn: 700,
				type: 'iframe',
				mainClass: 'mfp-fade',
				removalDelay: 160,
				preloader: false,
				fixedContentPos: true
		});
	});
*/

$('.popup--opener').magnificPopup({
	type: 'inline',
	preloader: false,
	//focus: '#name',
  });
  
$('.popup--opener').trigger("click");

})(jQuery);
